/**
 * Load Google base functions
 */

(function (wpm, $, undefined) {

	wpm.googleConfigConditionsMet = ({type = null, log = false}) => {

		// Always returns true if Google Consent Mode is active
		if (wpmDataLayer?.pixels?.google?.consent_mode?.is_active) {
			if (log) pmw.console.log("Google Consent Mode is active. Loading the Google pixel.");
			return true;
		}

		// If a type is passed, return true if the user has given consent to that type
		// or false if the user has not given consent to that type.
		if (type) {
			if (!!wpm.consent.categories.get()[type]) {
				if (log) pmw.console.log("The category " + type + " has been approved. Loading the Google pixel.");
				return true;
			} else {
				if (log) pmw.console.log("The category " + type + " has been denied. Not loading the Google pixel.");
				return false;
			}
		}

		// If no type is passed, return true if the user has given consent to the statistics or marketing categories.
		if (!!(wpm.consent.categories.get().marketing || wpm.consent.categories.get().statistics)) {
			if (log) pmw.console.log("The categories statistics and/or marketing are approved. Loading the Google pixel.");
			return true;
		}

		return false;
	};

	wpm.fireGtagGoogleAds = () => {
		try {
			wpmDataLayer.pixels.google.ads.state = "loading";

			if (wpmDataLayer?.pixels?.google?.enhanced_conversions?.is_active) {
				Object.keys(wpmDataLayer.pixels.google.ads.conversion_ids).forEach((key) => {
					gtag("config", key, {"allow_enhanced_conversions": true});
				});
			} else {
				Object.keys(wpmDataLayer.pixels.google.ads.conversion_ids).forEach((key) => {
					gtag("config", key);
				});
			}

			if (wpmDataLayer?.pixels?.google?.ads?.conversion_ids && wpmDataLayer?.pixels?.google?.ads?.phone_conversion_label && wpmDataLayer?.pixels?.google?.ads?.phone_conversion_number) {
				gtag("config", Object.keys(wpmDataLayer.pixels.google.ads.conversion_ids)[0] + "/" + wpmDataLayer.pixels.google.ads.phone_conversion_label, {
					phone_conversion_number: wpmDataLayer.pixels.google.ads.phone_conversion_number,
				});
			}

			wpmDataLayer.pixels.google.ads.state = "ready";
		} catch (e) {
			console.error(e);
		}
	};

	// https://developers.google.com/tag-platform/gtagjs/reference
	wpm.fireGtagGoogleAnalyticsGA4 = () => {

		try {
			wpmDataLayer.pixels.google.analytics.ga4.state = "loading";

			let parameters = wpmDataLayer.pixels.google.analytics.ga4.parameters;

			if (wpmDataLayer?.pixels?.google?.analytics?.ga4?.debug_mode) {
				parameters.debug_mode = true;
			}

			gtag("config", wpmDataLayer.pixels.google.analytics.ga4.measurement_id, parameters);

			wpmDataLayer.pixels.google.analytics.ga4.state = "ready";
		} catch (e) {
			console.error(e);
		}
	};

	/**
	 * Check if Google is active
	 *
	 * @returns {boolean}
	 */
	wpm.isGoogleActive = () => {

		// If GA4 is active, return true
		if (wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) return true;

		// If Google Ads is active, return true
		if (!jQuery.isEmptyObject(wpmDataLayer?.pixels?.google?.ads?.conversion_ids)) return true;

		return false;
	};

	wpm.getGoogleGtagId = () => {

		if (wpmDataLayer?.pixels?.google?.tracking_id) {
			return wpmDataLayer.pixels.google.tracking_id;
		}

		if (
			wpmDataLayer?.pixels?.google?.ads?.conversion_ids
			&& Object.keys(wpmDataLayer.pixels.google.ads.conversion_ids)[0]
		) {
			return Object.keys(wpmDataLayer.pixels.google.ads.conversion_ids)[0];
		}

		if (wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) {
			return wpmDataLayer.pixels.google.analytics.ga4.measurement_id;
		}

		return "";
	};


	wpm.loadGoogle = () => {

		if (!wpm.isGoogleActive()) {
			return;
		}

		wpmDataLayer.pixels.google.state = "loading";

		wpm.loadScriptAndCacheIt("https://www.googletagmanager.com/gtag/js?id=" + wpm.getGoogleGtagId())
			.then((script, textStatus) => {

				try {

					// If Google TCF is active, output the TCF script
					// https://developers.google.com/tag-platform/security/guides/implement-TCF-strings
					if (wpmDataLayer?.pixels?.google?.tcf_support) {
						window["gtag_enable_tcf_support"] = true;
					}

					// Initiate Google dataLayer and gtag
					window.dataLayer = window.dataLayer || [];

					window.gtag = function gtag() {

						pmw.console.log("gtag called with", arguments);
						pmw.consent.api.processExternalGcmConsentUpdate_experimental(arguments);

						// Delete the source property from the arguments object
						// It is a helper property that is not needed by gtag for regular calls
						if (arguments.length === 3 && arguments[2]?.source) {
							delete arguments[2].source;
						}

						dataLayer.push(arguments);
					};

					// Google Consent Mode
					if (wpmDataLayer?.pixels?.google?.consent_mode?.is_active) {

						let google_consent_settings = {
							ad_personalization     : wpm.consent.categories.get().marketing ? "granted" : "denied",
							ad_storage             : wpm.consent.categories.get().marketing ? "granted" : "denied",
							ad_user_data           : wpm.consent.categories.get().marketing ? "granted" : "denied",
							analytics_storage      : wpm.consent.categories.get().statistics ? "granted" : "denied",
							functionality_storage  : wpm.consent.categories.get().preferences ? "granted" : "denied",
							personalization_storage: wpm.consent.categories.get().preferences ? "granted" : "denied",
							security_storage       : wpm.consent.categories.get().necessary ? "granted" : "denied",
							wait_for_update        : wpmDataLayer.pixels.google.consent_mode.wait_for_update,
						};

						if (wpmDataLayer?.general?.consent_management?.restricted_regions) {
							google_consent_settings.region = wpmDataLayer.general.consent_management.restricted_regions;
						}

						pmw.console.log("Google Consent Mode settings", google_consent_settings);

						gtag("consent", "default", google_consent_settings);
						gtag("set", "ads_data_redaction", wpmDataLayer.pixels.google.consent_mode.ads_data_redaction);
						gtag("set", "url_passthrough", wpmDataLayer.pixels.google.consent_mode.url_passthrough);
					}

					// Google Linker
					// https://developers.google.com/gtagjs/devguide/linker
					if (wpmDataLayer?.pixels?.google?.linker?.settings) {
						gtag("set", "linker", wpmDataLayer.pixels.google.linker.settings);
					}

					gtag("js", new Date());
					gtag("set", "developer_id.dNDI5Yz", true);

					/**
					 * Set Enhanced Conversion Data for Google Ads and GA4 on the order received page
					 *
					 * https://support.google.com/google-ads/answer/9888145#zippy=%2Cvalidate-your-implementation-using-chrome-developer-tools
					 * GA4: https://support.google.com/analytics/answer/14171598?sjid=9970117052450433556-EU
					 */
					if (
						wpmDataLayer?.shop?.page_type
						&& "order_received_page" === wpmDataLayer.shop.page_type
						&& wpmDataLayer?.order?.google?.enhanced_conversion_data
					) {
						gtag("set", "user_data", wpmDataLayer.order.google.enhanced_conversion_data);
					}

					// Google Ads loader
					if (!jQuery.isEmptyObject(wpmDataLayer?.pixels?.google?.ads?.conversion_ids)) {  // Only run if the pixel has set up
						if (wpm.googleConfigConditionsMet({type: "marketing"})) {  							// Only run if cookie consent has been given
							wpm.fireGtagGoogleAds();
						} else {
							wpm.consent.logSuppressedPixel("marketing", "Google Ads");
						}
					}

					// GA4 loader
					if (wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) {  			// Only run if the pixel has set up

						if (wpm.googleConfigConditionsMet({type: "statistics"})) {						// Only run if cookie consent has been given
							wpm.fireGtagGoogleAnalyticsGA4();
						} else {
							wpm.consent.logSuppressedPixel("statistics", "GA4");
						}
					}

					wpmDataLayer.pixels.google.state = "ready";

					wpm.triggerQueuedEvents("gtag");

				} catch (e) {
					console.error(e);
				}
			});
	};

	wpm.gtagLoaded = () => {
		return new Promise((resolve, reject) => {

			if (typeof wpmDataLayer?.pixels?.google?.state === "undefined") reject();

			let startTime = 0;
			let timeout   = 5000;
			let frequency = 200;

			(function wait() {
				if (wpmDataLayer?.pixels?.google?.state === "ready") return resolve();
				if (startTime >= timeout) {
					pmw.console.error("Google gtag failed to load. Probably a third party script is blocking it.");
					return reject();
				}
				startTime += frequency;
				setTimeout(wait, frequency);
			})();
		});
	};

	/**
	 * Update Google Consent Mode
	 *
	 * @param statistics
	 * @param marketing
	 * @param preferences
	 * @param necessary
	 */
	wpm.updateGoogleConsentMode = ({
									   statistics = wpm.consent.categories.get().statistics,
									   marketing = wpm.consent.categories.get().marketing,
									   preferences = wpm.consent.categories.get().preferences,
									   necessary = wpm.consent.categories.get().necessary,
								   }) => {

		try {
			if (!window.gtag) return;

			let data = {
				analytics_storage      : statistics ? "granted" : "denied",
				ad_storage             : marketing ? "granted" : "denied",
				ad_user_data           : marketing ? "granted" : "denied",
				ad_personalization     : marketing ? "granted" : "denied",
				functionality_storage  : preferences ? "granted" : "denied",
				personalization_storage: preferences ? "granted" : "denied",
				security_storage       : necessary ? "granted" : "denied",
				source                 : "pmw",
			};

			gtag("consent", "update", data);
			pmw.console.log("Google consent mode updated", data);
		} catch (e) {
			console.error(e);
		}
	};


}(window.wpm = window.wpm || {}, jQuery));
